const moment = require('moment-timezone');
const axios = require('axios');

async function optionsCost(startDate, endDate, page, keyword, searchType, sortBy, sortDesc) {
    const token = `${localStorage.getItem('token')}`
    const uid = `${localStorage.getItem('shopsUid')}`
    const shopObjectId = `${localStorage.getItem('shopObjectId')}`
    const headers = {
        Authorization: token,
        shopObjectId: shopObjectId,
    }
    const params = {
        shopObjectId: shopObjectId,
        startAt: startDate,
        endAt: endDate,
        page: page
    }
    if (keyword && keyword.trim() !== '') {
        params.searchVal = keyword;
    }
    if (searchType) {
        params.searchAttr = searchType;
    }
    if (sortBy) {
        params.sortAttr = sortBy;
        params.sortOrder = sortDesc;
    }
    try {
        const res = await axios({
            baseURL: process.env.VUE_APP_SERVICE_URL,
            url: '/api/v1.0/' + uid + '/receipt/product/option',
            headers: headers,
            params: params,
            method: 'GET',
        })

        return res.data
    } catch (error) {
        console.log(error)
        return null
    }
}

async function optionsCostByDate(startDate, endDate, page, sortBy, sortDesc) {
    const token = `${localStorage.getItem('token')}`
    const uid = `${localStorage.getItem('shopsUid')}`
    const shopObjectId = `${localStorage.getItem('shopObjectId')}`
    const headers = {
        Authorization: token,
        shopObjectId: shopObjectId,
    }
    const params = {
        shopObjectId: shopObjectId,
        startAt: startDate,
        endAt: endDate,
        page: page
    }
    if (sortBy) {
        params.sortAttr = sortBy;
        params.sortOrder = sortDesc;
    }
    try {
        const res = await axios({
            baseURL: process.env.VUE_APP_SERVICE_URL,
            url: '/api/v1.0/' + uid + '/receipt/product/optionbydate',
            params: params,
            headers: headers,
            method: 'GET',
        })
        const responseData = {
            'data': res.data,
        }
        return responseData
    } catch (error) {
        console.log(error)
        return null
    }
}

async function exportOptionsCostReport(startDate, endDate, locale, keyword, searchType, sortBy, sortDesc) {
    const token = `${localStorage.getItem('token')}`;
    const uid = `${localStorage.getItem('shopsUid')}`;
    const shopObjectId = `${localStorage.getItem('shopObjectId')}`;
    const headers = {
        Authorization: token,
        shopObjectId: shopObjectId,
    };
    const params = {
        shopObjectId: shopObjectId,
        lang: locale,
        startAt: startDate,
        endAt: endDate,
    };
    if (keyword && keyword.trim() !== '') {
        params.searchVal = keyword;
    }
    if (searchType) {
        params.searchAttr = searchType;
    }
    if (sortBy) {
        params.sortAttr = sortBy;
        params.sortOrder = sortDesc;
    }
    try {
        const res = await axios({
            baseURL: process.env.VUE_APP_SERVICE_URL,
            url: '/api/v1.0/' + uid + '/receipt/product/option/excel',
            params: params,
            headers: headers,
            method: 'GET',
            responseType: 'blob', // important for excel export
        });
        return res.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

async function exportOptionsCostByDateReport(startDate, endDate, locale, sortBy, sortDesc) {
    const token = `${localStorage.getItem('token')}`
    const uid = `${localStorage.getItem('shopsUid')}`
    const shopObjectId = `${localStorage.getItem('shopObjectId')}`
    const headers = {
        Authorization: token,
        shopObjectId: shopObjectId,
    }
    const params = {
        shopObjectId: shopObjectId,
        lang: locale,
        startAt: startDate,
        endAt: endDate,
    }
    if (sortBy) {
        params.sortAttr = sortBy;
        params.sortOrder = sortDesc;
    }
    try {
        const res = await axios({
            baseURL: process.env.VUE_APP_SERVICE_URL,
            url: '/api/v1.0/' + uid + '/receipt/product/optionbydate/excel',
            params: params,
            headers: headers,
            method: 'GET',
            responseType: 'blob', // important for excel export
        });
        return res.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export { optionsCost, optionsCostByDate, exportOptionsCostByDateReport, exportOptionsCostReport}