<template>
  <div v-if="isPermission">
    <select-shop :loadingButton="loadingButton" v-on:getData="loadData"></select-shop>
    <CCard class="shadow-sm">
      <CCardBody>
        <div class="row">
          <h2 id="optionSalesByDateTitle" v-if="optionSalesByDate" class="col-md-10 col-sm-9 col-8 font-weight-normal">
            {{ $t("optionSalesByDateReportTXT") }}
          </h2>
          <h2 v-else id="optionSalesByCostTitle" class="col-md-10 col-sm-9 col-8 font-weight-normal">
            {{ $t("optionSalesByCostReportTXT") }}
          </h2>
          <div class="col-md-2 col-sm-3 col-4 text-right text-success">
            <CButton id="optionSalesExportButton" v-if="isExport" v-on:click="exportReport()" block color="info">
              <img src="/img/file-excel.png" alt="Excel Icon" style="width: 16px; height: 16px; margin-right: 8px; vertical-align: middle; position: relative; top: -2px;" />{{ $t('export') }}
            </CButton>
          </div>
        </div>
        <show-date></show-date>
        <CRow v-if="!optionSalesByDate" >
          <CCol lg="3" md="3" sm="3" col="3" class="mb-3">
            <select id="optionSalesSearchType" class="custom-select" v-model="searchType">
              <option :id="'optionSalesOptionSearch' + index " v-for="(option, index) in searchOptions" :key="option.value" :value="option.value">
                {{ option.label }}
              </option>
            </select>
          </CCol>
          <CCol lg="7" md="7" sm="7" col="7">
            <div style="margin-left: 2px">
              <CInput id="optionSalesKeywordInput" v-model.trim="keyword" :placeholder="$t('search')" :disabled="searchType === ''" />
            </div>
          </CCol>
          <CCol lg="2" md="2" sm="2" col="2">
            <CButton id="optionSalesSearchButton" v-on:click="loadData()" block color="success">
              {{ $t("search") }}
            </CButton>
          </CCol>
        </CRow>
        <CRow>
          <CCol sm="12" lg="12">
            <div class="sorting-container">
              <span class="sorting-label">{{ $t('sortBy') }} : </span>
              <div class="sorting-buttons">
                <CButton id="optionSalesSortName" :class="getButtonClass('optionItem.name')" v-on:click="toggleSort('optionItem.name')">
                  {{ $t('txtOption') }} <i :class="getArrowClass('optionItem.name')"></i>
                </CButton>
                <CButton id="optionSalesSortSales" :class="getButtonClass('quantity')" v-on:click="toggleSort('quantity')">
                  {{ $t('salesQty') }} <i :class="getArrowClass('quantity')"></i>
                </CButton>
                <CButton id="optionSalesSortTotal" :class="getButtonClass('total')" v-on:click="toggleSort('total')">
                  {{ $t('totalAmount') }} <i :class="getArrowClass('total')"></i>
                </CButton>
              </div>
            </div>
            <hr />
            <div style="overflow-x: auto">
              <DataTable
                id="optionSalesDataTable"
                style="white-space: nowrap"
                :items="items"
                :fields="fields"
                hover
                border
              ></DataTable>
            </div>
          </CCol>
        </CRow>
        <pagination :meta_data="meta_data" v-on:next="loadData"> </pagination>
      </CCardBody>
    </CCard>
  </div>
  <div v-else>
    <access-data></access-data>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import moment from "moment";
import report from "@/services/report";
import util from "@/util/util";
import DataTable from "@/containers/DataTable";
import permis from "@/util/permission";
import Pagination from "@/containers/Pagination";
import { fetchOptionSalesByCost, fetchOptionSalesByDate, exportOptionSalesByCostReport, exportOptionSalesByDateReport } from "@/controller/report/optionSalesController";

export default {
  components: {
    DataTable,
    Pagination,
  },
  data() {
    return {
      isLoading: false,
      loadingButton: false,
      tableData: [],
      optionSalesByDate: false,
      searchType: "",
      keyword: '',
      sortBy: '',
      sortDesc: 'asc',
      meta_data: {
        last_page: null,
        current_page: 1,
        prev_page_url: null,
        items: 0,
        limit: null,
        itemCount: null,
      },
    };
  },
  computed: {
    ...mapGetters(["shops", "date", "users", "permissions"]),
    isPermission() {
      return permis.findPermissionRead("report", this.$route.path);
    },
    isExport() {
      return permis.findPermissionExport('report', this.$route.path)
    },
    shopObjectId: {
      get() {
        return this.$store.getters.shopObjectId;
      },
      set(newValue) {
        return this.$store.dispatch("setShop", newValue);
      },
    },
    uid() {
      return `${localStorage.getItem("shopsUid")}`;
    },
    startDate() {
      return moment(String(this.date.start)).format("YYYY-MM-DD");
    },
    endDate() {
      return moment(String(this.date.end)).format("YYYY-MM-DD");
    },
    fields() {
      if (this.optionSalesByDate) {
        return [
          { key: "paidDate", label: this.$i18n.t("date"), _classes: "text-left text-dark font-weight-normal" },
          { key: "productCategory", label: this.$i18n.t("category"), _classes: "text-left text-dark font-weight-normal" },
          { key: "productPLUName", label: this.$i18n.t("productName"), _classes: "text-left text-dark font-weight-normal" },
          { key: "optionItemName", label: this.$i18n.t("txtOption"), _classes: "text-left text-dark font-weight-normal" },
          { key: "quantity", label: this.$i18n.t("salesQty"), _classes: "text-right text-dark font-weight-normal" },
          { key: "total", label: this.$i18n.t("totalAmount"), _classes: "text-right text-dark font-weight-normal" },
        ];
      } else {
        return [
          { key: "productPLUName", label: this.$i18n.t("productName"), _classes: "text-left text-dark font-weight-normal" },
          { key: "optionGroupName", label: this.$i18n.t("txtOptionCategory"), _classes: "text-left text-dark font-weight-normal" },
          { key: "optionItemName", label: this.$i18n.t("txtOption"), _classes: "text-left text-dark font-weight-normal" },
          { key: "quantity", label: this.$i18n.t("salesQty"), _classes: "text-right text-dark font-weight-normal" },
          { key: "price", label: this.$i18n.t("exceedUc"), _classes: "text-right text-dark font-weight-normal" },
          { key: "total", label: this.$i18n.t("totalAmount"), _classes: "text-right text-dark font-weight-normal" },
          { key: "totalCost", label: this.$i18n.t("totalCost"), _classes: "text-right text-dark font-weight-normal" },
          { key: "profit", label: this.$i18n.t("profit"), _classes: "text-right text-dark font-weight-normal" }
        ];
      }
    },
    searchOptions() {
      return [
        { value: "", label: this.$t("allItems") },
        { value: "optionGroup.name", label: this.$t("optionSalesMenu") },
        { value: "optionItem.name", label: this.$t("txtOption") },
        { value: "productPLU.name", label: this.$t("productName") }
      ];
    },
    items() {
      if (this.tableData.length > 0) {
        return this.tableData.map(data => {
          if (this.optionSalesByDate) {
            return {
              paidDate: data.paidDate,
              productCategory: data.productCategory,
              optionItemName: data.optionItemName,
              productPLUName: data.productPLUName,
              quantity: data.quantity,
              total: util.convertCurrency(data.total)
            };
          } else {
            return {
              productPLUName: data.productPLUName,
              optionGroupName: data.optionGroupName,
              optionItemName: data.optionItemName,
              price: util.convertCurrency(data.price),
              quantity: data.quantity,
              cost: util.convertCurrency(data.cost),
              totalCost: util.convertCurrency(data.totalCost),
              stdCost: util.convertCurrency(data.stdCost),
              total: util.convertCurrency(data.total),
              profit: util.convertCurrency(data.profit)
            };
          }
        });
      } else {
        return [];
      }
    },
  },
  watch: {
    '$i18n.locale': function(newLocale, oldLocale) {
      this.searchOptions = this.searchOptions.map(option => ({
        value: option.value,
        label: this.$t(option.label)
      }));
    }
  },
  created() {
    this.checkPath()
  },
  watch: {
    '$route.name': 'checkPath'
  },
  methods: {
    ...util,
    checkPath() {
      if (this.$route.name === "OptionSalesByDate") {
        this.optionSalesByDate = true;
        this.loadData();
      } else if (this.$route.name === "OptionSalesByCost") {
        this.optionSalesByDate = false;
        this.loadData();
      }
    },
    async loadData(page = 1) {
      this.isLoading = true;
      try {
        if (this.optionSalesByDate) {
          const response = await fetchOptionSalesByDate(this.startDate, this.endDate, page, this.sortBy, this.sortDesc);
          this.tableData = response.optionSalesByDateData;
          const clonedMetaData = JSON.parse(JSON.stringify(response.metaData));
          this.meta_data.last_page = clonedMetaData.pageCount;
          this.meta_data.current_page = clonedMetaData.currentPage;
          this.meta_data.itemCount = clonedMetaData.itemCount;
          this.meta_data.limit = clonedMetaData.limit;
          this.meta_data.items = clonedMetaData.items;
        } else {
          const response = await fetchOptionSalesByCost(this.startDate, this.endDate, page, this.keyword, this.searchType, this.sortBy, this.sortDesc);
          this.tableData = response.optionSalesByCostData;
          const clonedMetaData = JSON.parse(JSON.stringify(response.metaData));
          this.meta_data.last_page = clonedMetaData.pageCount;
          this.meta_data.current_page = clonedMetaData.currentPage;
          this.meta_data.itemCount = clonedMetaData.itemCount;
          this.meta_data.limit = clonedMetaData.limit;
          this.meta_data.items = clonedMetaData.items;
        }

        this.isLoading = false;
        this.loadingButton = true;
      } catch (error) {
        console.error("Error loading data:", error);
        this.isLoading = false;
        this.loadingButton = false;
      }
    },
    toggleSort(key) {
      if (this.sortBy === key) {
        this.sortDesc = this.sortDesc === 'asc' ? 'desc' : 'asc';
      } else {
        this.sortBy = key;
        this.sortDesc = 'asc';
      }
      this.loadData();  // Send the API request only when the button is active
    },
    getButtonClass(key) {
      return this.sortBy === key ? 'btn btn-active' : 'btn btn-inactive';
    },
    getArrowClass(key) {
      if (this.sortBy === key) {
        return this.sortDesc === 'desc' ? 'fi fi-rr-arrow-small-down' : 'fi fi-rr-arrow-small-up';
      }
      return '';
    },
    async exportReport() {
      try {
        let response;
        if (this.optionSalesByDate) {
          response = await exportOptionSalesByDateReport(this.startDate, this.endDate, this.$i18n.locale, this.sortBy, this.sortDesc);
        } else {
          response = await exportOptionSalesByCostReport(this.startDate, this.endDate, this.$i18n.locale, this.keyword, this.searchType, this.sortBy, this.sortDesc);
        }

        if (response) {
          const url = window.URL.createObjectURL(new Blob([response]));
          const link = document.createElement("a");
          link.href = url;
          const filename = this.optionSalesByDate ? this.$t('optionSalesByDateReportTXT') : this.$t('optionSalesByCostReportTXT');
          link.setAttribute("download", filename+'.xlsx');
          document.body.appendChild(link);
          link.click();
        }
      } catch (error) {
        console.error('Error exporting report:', error);
      }
    },
  },
};
</script>

<style scoped>
.sorting-container {
  display: flex;
  align-items: center;
  background-color: #f8f9fa;
  padding: 10px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #dfe1e5;
}

.sorting-label {
  margin-right: 10px;
  color: #47525e;
  font-weight: 500;
}

.sorting-buttons {
  display: flex;
  gap: 10px; /* Increased gap for more spacing */
}
.btn-inactive {
  background-color: white;
  color: #47525e;
}

.btn-inactive:hover {
  background-color: #e9ecef;
}

.btn-active {
  background-color: #3abc98;
  color: white;
}
</style>
