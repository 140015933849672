const optionModel = require('../../model/report/option')

exports.fetchOptionSalesByCost = async (startDate, endDate, page, keyword, searchType, sortBy, sortDesc) => {
  try {
    let optionSalesByCost = await optionModel.optionsCost(startDate, endDate, page, keyword, searchType, sortBy, sortDesc);
    if (optionSalesByCost.error && optionSalesByCost.error.code === 4000) {
      return false;
    } else {
      let optionSalesByCostData = optionSalesByCost.data.map(item => {
        if (item.hasCost) {
          const price = item.price ?? 0;
          const quantity = item.quantity ?? 0;
          return {
            productPLUName: item.productPLU?.name ?? "-",
            optionGroupName: item.optionGroup?.name ?? "-",
            optionItemName: item.optionItem?.name ?? "-",
            price: item.price ?? "-",
            quantity: item.quantity ?? "-",
            cost: item.cost ?? "-",
            totalCost: item.totalCost ?? "-",
            stdCost: item.stdCost ?? "-",
            total: item.total ?? "-",
            profit: item.profit ?? "-"
          };
        } else {
          return {
            productPLUName: item.productPLU?.name ?? "-",
            optionGroupName: item.optionGroup?.name ?? "-",
            optionItemName: item.optionItem?.name ?? "-",
            price: item.price ?? "-",
            quantity: item.quantity ?? "-",
            cost: 0,
            totalCost: 0,
            stdCost: 0,
            total: 0,
            profit: 0
          };
        }
      });
      const metaData = {
        items: optionSalesByCost.paginate.items || 0,
        pageCount: optionSalesByCost.paginate.pageCount,
        currentPage: optionSalesByCost.paginate.currentPage,
        itemCount: optionSalesByCost.paginate.itemCount,
        limit: optionSalesByCost.paginate.perPage
      };
      return {optionSalesByCostData, metaData};
    }
  } catch (error) {
    console.error('Error fetching table data:', error);
    return null;
  }
}

exports.fetchOptionSalesByDate = async (startDate, endDate, page, sortBy, sortDesc) => {
  try {
    let optionSalesByDate = await optionModel.optionsCostByDate(startDate, endDate, page, sortBy, sortDesc);
    if (optionSalesByDate.error && optionSalesByDate.data.error.code === 4000) {
      return false;
    } else {
      let optionSalesByDateData = optionSalesByDate.data.data.map(item => {
        return {
          paidDate: item.paidDate ? `${item.paidDate.slice(6, 8)}/${item.paidDate.slice(4, 6)}/${item.paidDate.slice(0, 4)}` : '-',
          productCategory: item.category && item.category.name ? item.category.name : '-',
          optionItemName: item.optionItem && item.optionItem.name ? item.optionItem.name : '-',
          productPLUName: item.productPLU && item.productPLU.name ? item.productPLU.name : '-',
          quantity: item.quantity !== null ? item.quantity : '-',
          total: item.total !== null ? item.total : '-'
        };
      });
      const metaData = {
        items: optionSalesByDate.data.paginate.items || 0,
        pageCount: optionSalesByDate.data.paginate.pageCount,
        currentPage: optionSalesByDate.data.paginate.currentPage,
        itemCount: optionSalesByDate.data.paginate.itemCount,
        limit: optionSalesByDate.data.paginate.perPage
      };
      return {optionSalesByDateData, metaData};
    }
  } catch (error) {
    console.error('Error fetching table data:', error);
    return null;
  }

}

exports.exportOptionSalesByCostReport = async (startDate, endDate, locale, keyword, searchType, sortBy, sortDesc) => {
  try {
      const result = await optionModel.exportOptionsCostReport(startDate, endDate, locale, keyword, searchType, sortBy, sortDesc);
      return result;
  } catch (error) {
      console.error('Error exporting Option Sales by Cost Report:', error);
      return null;
  }
}

exports.exportOptionSalesByDateReport = async (startDate, endDate, locale, sortBy, sortDesc) => {
  try {
      const result = await optionModel.exportOptionsCostByDateReport(startDate, endDate, locale, sortBy, sortDesc);
      return result;
  } catch (error) {
      console.error('Error exporting Option Sales by Date Report:', error);
      return null;
  }
}