<template>
  <nav>
    <div class="row">
      <div class="col-6">
        <ul class="pagination pagination-md">
          <li class="page-item" v-if="meta_data.current_page != 1">
            <a
              href="#"
              class="page-link"
              @click="next(meta_data.current_page - 1)"
            >
              &laquo;
            </a>
          </li>
          <li
            class="page-item disabled"
            v-else-if="meta_data.current_page === 1"
          >
            <a
              href="#"
              class="page-link"
              @click="next(meta_data.current_page - 1)"
            >
              &laquo;
            </a>
          </li>
          <li
            class="page-item"
            aria-hidden="true"
            v-if="meta_data.current_page > 4"
          >
            <a href="#" class="page-link" @click="next(1)"> 1 </a>
          </li>
          <li class="page-item disabled" v-if="meta_data.current_page > 5">
            <a href="#" class="page-link"> ... </a>
          </li>
          <li
            class="page-item"
            v-for="page in meta_data.last_page"
            :key="page"
            :class="{ active: meta_data.current_page === page }"
          >
            <a
              href="#"
              @click.prevent="next(page)"
              class="page-link"
              v-if="
                page >= meta_data.current_page - 3 &&
                  page <= meta_data.current_page + 3
              "
            >
              {{ page }}
            </a>
          </li>
          <li
            class="page-item disabled"
            v-if="meta_data.current_page < meta_data.last_page - 4"
          >
            <a href="#" class="page-link"> ... </a>
          </li>
          <li
            class="page-item"
            aria-hidden="true"
            v-if="meta_data.current_page < meta_data.last_page - 3"
          >
            <a href="#" class="page-link" @click="next(meta_data.last_page)">
              {{ meta_data.last_page }}
            </a>
          </li>
          <li
            class="page-item"
            :class="{
              disabled: meta_data.current_page === meta_data.last_page,
            }"
          >
            <a
              href="#"
              class="page-link"
              @click="next(meta_data.current_page + 1)"
            >
              &raquo;
            </a>
          </li>
        </ul>
      </div>
      <!-- <div class="col-6 text-right">Showing 0 to 0 of 0 entries</div> -->
      <div class="col-6 text-right">
        Showing {{ showfrom }} to {{ showTo }} of {{ all }} entries
      </div>
    </div>
  </nav>
</template>

<script>
export default {
  props: ['meta_data'],
  computed: {
    showfrom() {
      let data = 0
      let current_page =
        this.meta_data.current_page * this.meta_data.limit -
        this.meta_data.limit +
        1
      if (this.meta_data.itemCount == 0 || this.meta_data.itemCount == null) {
        data = 0
      } else {
        data = current_page
      }
      return data
    },
    showTo() {
      let data = 0
      let showTo =
        this.meta_data.current_page * this.meta_data.limit -
        this.meta_data.limit +
        this.meta_data.itemCount
      if (this.meta_data.itemCount == 0 || this.meta_data.itemCount == null) {
        data = 0
      } else {
        data = showTo
      }
      return data
    },
    all() {
      let data = 0
      if (this.meta_data.items == 0 || this.meta_data.items == null) {
        data = 0
      } else {
        data = this.meta_data.items
      }
      return data
    },
  },
  methods: {
    next(page) {
      this.$emit('next', page)
    },
  },
}
</script>
